import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

type Photo = {
  img: ImageDataLike
  alt: string
}

type Props = {
  photos: Photo[]
}

const Section = styled.section`
  padding-bottom: 50px;

  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 80px;
  }

  ${({ theme }) => theme.media.xl.min} {
    padding-bottom: 100px;
  }
`

const PhotosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 25px;
  margin: 10px auto 0;
  max-width: 680px;

  ${({ theme }) => theme.media.md.min} {
    margin: 20px auto 0;
    row-gap: 35px;
  }

  ${({ theme }) => theme.media.lg.min} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
    max-width: unset;
    width: 100%;

    & > :nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    & > :nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }

    & > :nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
    }
  }
`

const ImgWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;

  ${({ theme }) => theme.media.lg.min} {
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

const ProductPhotos: React.FC<Props> = ({ photos }) => {
  return (
    <Section>
      <Container>
        <PhotosWrapper>
          {photos?.map(({ img, alt }, index) => (
            <ImgWrapper key={`photo-${index}`}>
              <LazyImage src={img} alt={alt} />
            </ImgWrapper>
          ))}
        </PhotosWrapper>
      </Container>
    </Section>
  )
}

export default ProductPhotos
