import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Automatyka/About'
import ProductPhotos from 'components/layout/Sections/AutomatykaProduct/ProductPhotos'

import Navigation from 'components/layout/Navigation/index'

const AutomatykaProductPage: React.FC<PageProps> = ({ pageContext, data }) => {
  const isZdalneSterowanie = pageContext.slug === 'zdalne-sterowanie-produkt'

  const breadcrumbs = {
    mobile: [
      {
        label: isZdalneSterowanie
          ? 'Automatyka'
          : pageContext.categoryTitle.replace('Napęd', 'Napędy'),
        link: isZdalneSterowanie
          ? '/automatyka/'
          : `/automatyka/${pageContext.category}/`,
      },
      {
        label: pageContext.title,
      },
    ],
    desktop: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Automatyka',
        link: '/automatyka/',
      },
      {
        label: pageContext.categoryTitle.replace('Napęd', 'Napędy'),
        link: `/automatyka/${pageContext.category}/`,
      },
      {
        label: pageContext.title,
      },
    ],
  }

  if (isZdalneSterowanie) {
    breadcrumbs.desktop.splice(2, 1)
  }

  const PAGE_SEO = data?.wpPage?.seo
  const PAGE = data?.wpPage?.twist200e

  const PHOTOS = PAGE?.productsGallery?.map((item) => ({
    img: item.twist200eGalleryImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: item.twist200eGalleryImg?.altText!,
  }))

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.productsImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        imgDesktop={
          PAGE?.productsImg?.localFile?.childImageSharp?.gatsbyImageData!
        }
        headingMobile={PAGE?.productsTitle}
        headingDesktop={PAGE?.productsTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.productsDescription} />
      <ProductPhotos photos={PHOTOS} />
    </Layout>
  )
}

export default AutomatykaProductPage

export const query = graphql`
  query AutomatykaProductPage($slug: String) {
    wpPage(slug: { eq: $slug }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      twist200e {
        productsCategory
        productsDescription
        productsTitle
        productsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        productsGallery {
          twist200eGalleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
